// Generated from /DSL/DSL.Library/graph.g4 by ANTLR 4.13.1
// jshint ignore: start
import antlr4 from 'antlr4';


const serializedATN = [4,0,116,1025,6,-1,2,0,7,0,2,1,7,1,2,2,7,2,2,3,7,3,
2,4,7,4,2,5,7,5,2,6,7,6,2,7,7,7,2,8,7,8,2,9,7,9,2,10,7,10,2,11,7,11,2,12,
7,12,2,13,7,13,2,14,7,14,2,15,7,15,2,16,7,16,2,17,7,17,2,18,7,18,2,19,7,
19,2,20,7,20,2,21,7,21,2,22,7,22,2,23,7,23,2,24,7,24,2,25,7,25,2,26,7,26,
2,27,7,27,2,28,7,28,2,29,7,29,2,30,7,30,2,31,7,31,2,32,7,32,2,33,7,33,2,
34,7,34,2,35,7,35,2,36,7,36,2,37,7,37,2,38,7,38,2,39,7,39,2,40,7,40,2,41,
7,41,2,42,7,42,2,43,7,43,2,44,7,44,2,45,7,45,2,46,7,46,2,47,7,47,2,48,7,
48,2,49,7,49,2,50,7,50,2,51,7,51,2,52,7,52,2,53,7,53,2,54,7,54,2,55,7,55,
2,56,7,56,2,57,7,57,2,58,7,58,2,59,7,59,2,60,7,60,2,61,7,61,2,62,7,62,2,
63,7,63,2,64,7,64,2,65,7,65,2,66,7,66,2,67,7,67,2,68,7,68,2,69,7,69,2,70,
7,70,2,71,7,71,2,72,7,72,2,73,7,73,2,74,7,74,2,75,7,75,2,76,7,76,2,77,7,
77,2,78,7,78,2,79,7,79,2,80,7,80,2,81,7,81,2,82,7,82,2,83,7,83,2,84,7,84,
2,85,7,85,2,86,7,86,2,87,7,87,2,88,7,88,2,89,7,89,2,90,7,90,2,91,7,91,2,
92,7,92,2,93,7,93,2,94,7,94,2,95,7,95,2,96,7,96,2,97,7,97,2,98,7,98,2,99,
7,99,2,100,7,100,2,101,7,101,2,102,7,102,2,103,7,103,2,104,7,104,2,105,7,
105,2,106,7,106,2,107,7,107,2,108,7,108,2,109,7,109,2,110,7,110,2,111,7,
111,2,112,7,112,2,113,7,113,2,114,7,114,2,115,7,115,2,116,7,116,2,117,7,
117,2,118,7,118,2,119,7,119,2,120,7,120,2,121,7,121,2,122,7,122,2,123,7,
123,2,124,7,124,2,125,7,125,2,126,7,126,1,0,1,0,1,0,1,0,1,0,1,1,1,1,1,1,
1,1,1,1,1,1,1,1,1,1,1,1,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,2,1,3,1,3,1,3,1,3,
1,3,1,3,1,3,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,4,1,5,1,5,
1,5,1,5,1,5,1,5,1,5,1,5,1,5,1,6,1,6,1,6,1,6,1,6,1,6,1,6,1,6,1,6,1,6,1,6,
1,6,1,6,1,6,1,6,1,6,1,6,1,7,1,7,1,8,1,8,5,8,328,8,8,10,8,12,8,331,9,8,1,
8,1,8,1,8,1,8,5,8,337,8,8,10,8,12,8,340,9,8,1,8,1,8,3,8,344,8,8,1,9,1,9,
3,9,348,8,9,1,10,1,10,3,10,352,8,10,1,11,1,11,1,11,1,12,1,12,1,12,1,13,1,
13,1,14,1,14,1,15,1,15,1,16,1,16,1,17,1,17,1,18,1,18,1,18,1,18,1,18,1,18,
1,19,1,19,1,19,1,19,1,19,1,20,1,20,1,20,1,21,1,21,1,21,1,21,1,21,1,21,1,
22,1,22,1,22,1,22,1,22,1,22,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,1,23,
1,23,1,24,1,24,1,24,1,24,1,24,1,24,1,25,1,25,1,25,1,25,1,25,1,25,1,25,1,
25,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,26,1,27,1,27,1,27,
1,27,1,27,1,27,1,27,1,27,1,27,1,27,1,28,1,28,1,28,1,28,1,28,1,28,1,28,1,
28,1,29,1,29,1,29,1,29,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,30,1,31,1,31,
1,31,1,31,1,31,1,31,1,31,1,31,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,32,1,
32,1,32,1,32,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,1,33,
1,34,1,34,1,34,1,34,1,34,1,34,1,34,1,34,1,35,1,35,1,35,1,36,1,36,1,36,1,
36,1,36,1,37,1,37,1,37,1,37,1,37,1,38,1,38,1,38,1,38,1,38,1,38,1,38,1,39,
1,39,1,39,1,39,1,39,1,39,1,39,1,39,1,39,1,40,1,40,1,40,1,40,1,40,1,41,1,
41,1,41,1,41,1,41,1,41,1,41,1,41,1,42,1,42,1,42,1,42,1,43,1,43,1,43,1,43,
1,43,1,43,1,43,1,43,1,43,1,44,1,44,1,44,1,44,1,44,1,44,1,44,1,45,1,45,1,
45,1,45,1,45,1,46,1,46,1,46,1,47,1,47,1,47,1,47,1,47,1,47,1,47,1,48,1,48,
1,48,1,49,1,49,1,49,1,49,1,49,1,49,1,50,1,50,1,50,1,51,1,51,1,51,1,51,1,
51,1,51,1,51,1,51,1,52,1,52,1,52,1,52,1,52,1,52,1,52,1,52,1,53,1,53,1,53,
1,53,1,53,1,54,1,54,1,54,1,54,1,55,1,55,1,55,1,56,1,56,1,56,1,57,1,57,1,
57,1,57,1,57,1,57,1,57,1,57,1,57,1,57,1,57,1,57,1,58,1,58,1,58,1,58,1,58,
1,58,1,58,1,59,1,59,1,59,1,59,1,59,1,59,1,59,1,60,1,60,1,60,1,60,1,60,1,
60,1,60,1,60,1,60,1,60,1,60,1,60,1,60,1,61,1,61,1,61,1,61,1,61,1,61,1,61,
1,61,1,61,1,62,1,62,1,62,1,62,1,62,1,62,1,62,1,63,1,63,1,63,1,63,1,63,1,
64,1,64,1,64,1,64,1,65,1,65,1,65,1,65,1,65,1,65,1,65,1,66,1,66,1,66,1,66,
1,66,1,66,1,67,1,67,1,67,1,67,1,67,1,67,1,67,1,68,1,68,1,68,1,68,1,68,1,
69,1,69,1,69,1,69,1,69,1,69,1,69,1,70,1,70,1,70,1,70,1,70,1,70,1,70,1,70,
1,71,1,71,1,71,1,71,1,71,1,71,1,71,1,71,1,71,1,71,1,71,1,71,1,72,1,72,1,
72,1,72,1,72,1,73,1,73,1,73,1,73,1,74,1,74,1,74,1,74,1,74,1,75,1,75,1,75,
1,75,1,76,1,76,1,76,1,76,1,76,1,77,1,77,1,77,1,77,1,77,1,78,1,78,1,78,1,
78,1,78,1,79,1,79,1,80,1,80,1,81,3,81,774,8,81,1,81,1,81,1,82,3,82,779,8,
82,1,82,1,82,1,83,1,83,1,83,5,83,786,8,83,10,83,12,83,789,9,83,1,83,3,83,
792,8,83,1,83,1,83,4,83,796,8,83,11,83,12,83,797,1,83,3,83,801,8,83,1,83,
1,83,1,83,3,83,806,8,83,1,84,1,84,1,84,5,84,811,8,84,10,84,12,84,814,9,84,
3,84,816,8,84,1,85,1,85,3,85,820,8,85,1,85,4,85,823,8,85,11,85,12,85,824,
1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,86,1,86,3,86,836,8,86,1,87,1,87,1,87,
1,87,1,87,1,87,1,88,1,88,1,88,1,88,1,88,1,88,1,88,1,89,1,89,1,89,1,89,1,
89,1,89,1,89,1,89,1,90,1,90,1,90,1,90,1,90,1,90,1,90,1,91,1,91,1,91,1,91,
1,91,1,91,1,91,1,91,1,92,1,92,1,92,1,92,1,92,1,93,1,93,1,94,1,94,1,95,1,
95,1,96,1,96,1,97,1,97,1,98,1,98,1,99,1,99,1,100,1,100,1,101,1,101,1,102,
1,102,1,103,1,103,1,104,1,104,1,105,1,105,1,106,1,106,1,107,1,107,1,108,
1,108,1,109,1,109,1,109,1,110,1,110,1,110,1,111,1,111,1,111,1,112,1,112,
1,112,1,113,1,113,1,113,1,114,1,114,1,114,1,115,1,115,1,115,1,115,3,115,
933,8,115,1,116,1,116,1,117,1,117,1,117,1,117,1,117,3,117,942,8,117,1,118,
1,118,3,118,946,8,118,1,119,3,119,949,8,119,1,119,1,119,5,119,953,8,119,
10,119,12,119,956,9,119,1,119,3,119,959,8,119,1,119,1,119,1,119,5,119,964,
8,119,10,119,12,119,967,9,119,3,119,969,8,119,1,120,1,120,1,121,1,121,1,
122,1,122,3,122,977,8,122,1,123,1,123,1,123,1,123,5,123,983,8,123,10,123,
12,123,986,9,123,1,123,1,123,1,124,1,124,1,124,1,124,1,124,5,124,995,8,124,
10,124,12,124,998,9,124,1,124,1,124,1,124,1,124,1,124,1,125,1,125,1,125,
1,125,5,125,1009,8,125,10,125,12,125,1012,9,125,1,125,1,125,1,125,1,125,
1,125,1,126,4,126,1020,8,126,11,126,12,126,1021,1,126,1,126,2,996,1010,0,
127,1,1,3,2,5,3,7,4,9,5,11,6,13,7,15,0,17,8,19,0,21,0,23,0,25,0,27,9,29,
10,31,11,33,12,35,13,37,14,39,15,41,16,43,17,45,18,47,19,49,20,51,21,53,
22,55,23,57,24,59,25,61,26,63,27,65,28,67,29,69,30,71,31,73,32,75,33,77,
34,79,35,81,36,83,37,85,38,87,39,89,40,91,41,93,42,95,43,97,44,99,45,101,
46,103,47,105,48,107,49,109,50,111,51,113,52,115,53,117,54,119,55,121,56,
123,57,125,58,127,59,129,60,131,61,133,62,135,63,137,64,139,65,141,66,143,
67,145,68,147,69,149,70,151,71,153,72,155,73,157,74,159,75,161,76,163,77,
165,78,167,0,169,0,171,0,173,79,175,80,177,81,179,82,181,83,183,84,185,85,
187,86,189,87,191,88,193,89,195,90,197,91,199,92,201,93,203,94,205,95,207,
96,209,97,211,98,213,99,215,100,217,101,219,102,221,103,223,104,225,105,
227,106,229,107,231,108,233,109,235,110,237,111,239,112,241,0,243,0,245,
0,247,113,249,114,251,115,253,116,1,0,10,3,0,0,31,34,34,92,92,1,0,96,96,
8,0,34,34,39,39,92,92,98,98,102,102,110,110,114,114,116,116,1,0,48,57,1,
0,49,57,2,0,69,69,101,101,2,0,43,43,45,45,3,0,48,57,65,90,97,122,2,0,10,
10,13,13,3,0,9,10,13,13,32,32,1044,0,1,1,0,0,0,0,3,1,0,0,0,0,5,1,0,0,0,0,
7,1,0,0,0,0,9,1,0,0,0,0,11,1,0,0,0,0,13,1,0,0,0,0,17,1,0,0,0,0,27,1,0,0,
0,0,29,1,0,0,0,0,31,1,0,0,0,0,33,1,0,0,0,0,35,1,0,0,0,0,37,1,0,0,0,0,39,
1,0,0,0,0,41,1,0,0,0,0,43,1,0,0,0,0,45,1,0,0,0,0,47,1,0,0,0,0,49,1,0,0,0,
0,51,1,0,0,0,0,53,1,0,0,0,0,55,1,0,0,0,0,57,1,0,0,0,0,59,1,0,0,0,0,61,1,
0,0,0,0,63,1,0,0,0,0,65,1,0,0,0,0,67,1,0,0,0,0,69,1,0,0,0,0,71,1,0,0,0,0,
73,1,0,0,0,0,75,1,0,0,0,0,77,1,0,0,0,0,79,1,0,0,0,0,81,1,0,0,0,0,83,1,0,
0,0,0,85,1,0,0,0,0,87,1,0,0,0,0,89,1,0,0,0,0,91,1,0,0,0,0,93,1,0,0,0,0,95,
1,0,0,0,0,97,1,0,0,0,0,99,1,0,0,0,0,101,1,0,0,0,0,103,1,0,0,0,0,105,1,0,
0,0,0,107,1,0,0,0,0,109,1,0,0,0,0,111,1,0,0,0,0,113,1,0,0,0,0,115,1,0,0,
0,0,117,1,0,0,0,0,119,1,0,0,0,0,121,1,0,0,0,0,123,1,0,0,0,0,125,1,0,0,0,
0,127,1,0,0,0,0,129,1,0,0,0,0,131,1,0,0,0,0,133,1,0,0,0,0,135,1,0,0,0,0,
137,1,0,0,0,0,139,1,0,0,0,0,141,1,0,0,0,0,143,1,0,0,0,0,145,1,0,0,0,0,147,
1,0,0,0,0,149,1,0,0,0,0,151,1,0,0,0,0,153,1,0,0,0,0,155,1,0,0,0,0,157,1,
0,0,0,0,159,1,0,0,0,0,161,1,0,0,0,0,163,1,0,0,0,0,165,1,0,0,0,0,173,1,0,
0,0,0,175,1,0,0,0,0,177,1,0,0,0,0,179,1,0,0,0,0,181,1,0,0,0,0,183,1,0,0,
0,0,185,1,0,0,0,0,187,1,0,0,0,0,189,1,0,0,0,0,191,1,0,0,0,0,193,1,0,0,0,
0,195,1,0,0,0,0,197,1,0,0,0,0,199,1,0,0,0,0,201,1,0,0,0,0,203,1,0,0,0,0,
205,1,0,0,0,0,207,1,0,0,0,0,209,1,0,0,0,0,211,1,0,0,0,0,213,1,0,0,0,0,215,
1,0,0,0,0,217,1,0,0,0,0,219,1,0,0,0,0,221,1,0,0,0,0,223,1,0,0,0,0,225,1,
0,0,0,0,227,1,0,0,0,0,229,1,0,0,0,0,231,1,0,0,0,0,233,1,0,0,0,0,235,1,0,
0,0,0,237,1,0,0,0,0,239,1,0,0,0,0,247,1,0,0,0,0,249,1,0,0,0,0,251,1,0,0,
0,0,253,1,0,0,0,1,255,1,0,0,0,3,260,1,0,0,0,5,269,1,0,0,0,7,277,1,0,0,0,
9,284,1,0,0,0,11,297,1,0,0,0,13,306,1,0,0,0,15,323,1,0,0,0,17,343,1,0,0,
0,19,347,1,0,0,0,21,351,1,0,0,0,23,353,1,0,0,0,25,356,1,0,0,0,27,359,1,0,
0,0,29,361,1,0,0,0,31,363,1,0,0,0,33,365,1,0,0,0,35,367,1,0,0,0,37,369,1,
0,0,0,39,375,1,0,0,0,41,380,1,0,0,0,43,383,1,0,0,0,45,389,1,0,0,0,47,395,
1,0,0,0,49,405,1,0,0,0,51,411,1,0,0,0,53,419,1,0,0,0,55,430,1,0,0,0,57,440,
1,0,0,0,59,448,1,0,0,0,61,452,1,0,0,0,63,460,1,0,0,0,65,468,1,0,0,0,67,479,
1,0,0,0,69,491,1,0,0,0,71,499,1,0,0,0,73,502,1,0,0,0,75,507,1,0,0,0,77,512,
1,0,0,0,79,519,1,0,0,0,81,528,1,0,0,0,83,533,1,0,0,0,85,541,1,0,0,0,87,545,
1,0,0,0,89,554,1,0,0,0,91,561,1,0,0,0,93,566,1,0,0,0,95,569,1,0,0,0,97,576,
1,0,0,0,99,579,1,0,0,0,101,585,1,0,0,0,103,588,1,0,0,0,105,596,1,0,0,0,107,
604,1,0,0,0,109,609,1,0,0,0,111,613,1,0,0,0,113,616,1,0,0,0,115,619,1,0,
0,0,117,631,1,0,0,0,119,638,1,0,0,0,121,645,1,0,0,0,123,658,1,0,0,0,125,
667,1,0,0,0,127,674,1,0,0,0,129,679,1,0,0,0,131,683,1,0,0,0,133,690,1,0,
0,0,135,696,1,0,0,0,137,703,1,0,0,0,139,708,1,0,0,0,141,715,1,0,0,0,143,
723,1,0,0,0,145,735,1,0,0,0,147,740,1,0,0,0,149,744,1,0,0,0,151,749,1,0,
0,0,153,753,1,0,0,0,155,758,1,0,0,0,157,763,1,0,0,0,159,768,1,0,0,0,161,
770,1,0,0,0,163,773,1,0,0,0,165,778,1,0,0,0,167,805,1,0,0,0,169,815,1,0,
0,0,171,817,1,0,0,0,173,835,1,0,0,0,175,837,1,0,0,0,177,843,1,0,0,0,179,
850,1,0,0,0,181,858,1,0,0,0,183,865,1,0,0,0,185,873,1,0,0,0,187,878,1,0,
0,0,189,880,1,0,0,0,191,882,1,0,0,0,193,884,1,0,0,0,195,886,1,0,0,0,197,
888,1,0,0,0,199,890,1,0,0,0,201,892,1,0,0,0,203,894,1,0,0,0,205,896,1,0,
0,0,207,898,1,0,0,0,209,900,1,0,0,0,211,902,1,0,0,0,213,904,1,0,0,0,215,
906,1,0,0,0,217,908,1,0,0,0,219,910,1,0,0,0,221,913,1,0,0,0,223,916,1,0,
0,0,225,919,1,0,0,0,227,922,1,0,0,0,229,925,1,0,0,0,231,932,1,0,0,0,233,
934,1,0,0,0,235,941,1,0,0,0,237,945,1,0,0,0,239,968,1,0,0,0,241,970,1,0,
0,0,243,972,1,0,0,0,245,976,1,0,0,0,247,978,1,0,0,0,249,989,1,0,0,0,251,
1004,1,0,0,0,253,1019,1,0,0,0,255,256,5,102,0,0,256,257,5,114,0,0,257,258,
5,111,0,0,258,259,5,109,0,0,259,2,1,0,0,0,260,261,5,115,0,0,261,262,5,116,
0,0,262,263,5,97,0,0,263,264,5,114,0,0,264,265,5,116,0,0,265,266,5,105,0,
0,266,267,5,110,0,0,267,268,5,103,0,0,268,4,1,0,0,0,269,270,5,101,0,0,270,
271,5,120,0,0,271,272,5,105,0,0,272,273,5,116,0,0,273,274,5,105,0,0,274,
275,5,110,0,0,275,276,5,103,0,0,276,6,1,0,0,0,277,278,5,111,0,0,278,279,
5,112,0,0,279,280,5,101,0,0,280,281,5,110,0,0,281,282,5,101,0,0,282,283,
5,100,0,0,283,8,1,0,0,0,284,285,5,98,0,0,285,286,5,108,0,0,286,287,5,111,
0,0,287,288,5,99,0,0,288,289,5,107,0,0,289,290,5,77,0,0,290,291,5,101,0,
0,291,292,5,115,0,0,292,293,5,115,0,0,293,294,5,97,0,0,294,295,5,103,0,0,
295,296,5,101,0,0,296,10,1,0,0,0,297,298,5,97,0,0,298,299,5,109,0,0,299,
300,5,100,0,0,300,301,5,86,0,0,301,302,5,111,0,0,302,303,5,105,0,0,303,304,
5,99,0,0,304,305,5,101,0,0,305,12,1,0,0,0,306,307,5,108,0,0,307,308,5,97,
0,0,308,309,5,110,0,0,309,310,5,103,0,0,310,311,5,117,0,0,311,312,5,97,0,
0,312,313,5,103,0,0,313,314,5,101,0,0,314,315,5,68,0,0,315,316,5,101,0,0,
316,317,5,116,0,0,317,318,5,101,0,0,318,319,5,99,0,0,319,320,5,116,0,0,320,
321,5,101,0,0,321,322,5,100,0,0,322,14,1,0,0,0,323,324,8,0,0,0,324,16,1,
0,0,0,325,329,3,205,102,0,326,328,3,21,10,0,327,326,1,0,0,0,328,331,1,0,
0,0,329,327,1,0,0,0,329,330,1,0,0,0,330,332,1,0,0,0,331,329,1,0,0,0,332,
333,3,205,102,0,333,344,1,0,0,0,334,338,3,203,101,0,335,337,3,19,9,0,336,
335,1,0,0,0,337,340,1,0,0,0,338,336,1,0,0,0,338,339,1,0,0,0,339,341,1,0,
0,0,340,338,1,0,0,0,341,342,3,203,101,0,342,344,1,0,0,0,343,325,1,0,0,0,
343,334,1,0,0,0,344,18,1,0,0,0,345,348,3,15,7,0,346,348,3,25,12,0,347,345,
1,0,0,0,347,346,1,0,0,0,348,20,1,0,0,0,349,352,8,1,0,0,350,352,3,23,11,0,
351,349,1,0,0,0,351,350,1,0,0,0,352,22,1,0,0,0,353,354,5,92,0,0,354,355,
7,1,0,0,355,24,1,0,0,0,356,357,5,92,0,0,357,358,7,2,0,0,358,26,1,0,0,0,359,
360,5,43,0,0,360,28,1,0,0,0,361,362,5,45,0,0,362,30,1,0,0,0,363,364,5,42,
0,0,364,32,1,0,0,0,365,366,5,47,0,0,366,34,1,0,0,0,367,368,5,37,0,0,368,
36,1,0,0,0,369,370,5,97,0,0,370,371,5,112,0,0,371,372,5,112,0,0,372,373,
5,108,0,0,373,374,5,121,0,0,374,38,1,0,0,0,375,376,5,119,0,0,376,377,5,105,
0,0,377,378,5,116,0,0,378,379,5,104,0,0,379,40,1,0,0,0,380,381,5,97,0,0,
381,382,5,115,0,0,382,42,1,0,0,0,383,384,5,97,0,0,384,385,5,115,0,0,385,
386,5,121,0,0,386,387,5,110,0,0,387,388,5,99,0,0,388,44,1,0,0,0,389,390,
5,98,0,0,390,391,5,108,0,0,391,392,5,111,0,0,392,393,5,99,0,0,393,394,5,
107,0,0,394,46,1,0,0,0,395,396,5,98,0,0,396,397,5,108,0,0,397,398,5,111,
0,0,398,399,5,99,0,0,399,400,5,107,0,0,400,401,5,99,0,0,401,402,5,97,0,0,
402,403,5,108,0,0,403,404,5,108,0,0,404,48,1,0,0,0,405,406,5,99,0,0,406,
407,5,97,0,0,407,408,5,116,0,0,408,409,5,99,0,0,409,410,5,104,0,0,410,50,
1,0,0,0,411,412,5,99,0,0,412,413,5,111,0,0,413,414,5,109,0,0,414,415,5,109,
0,0,415,416,5,101,0,0,416,417,5,110,0,0,417,418,5,116,0,0,418,52,1,0,0,0,
419,420,5,99,0,0,420,421,5,111,0,0,421,422,5,110,0,0,422,423,5,100,0,0,423,
424,5,105,0,0,424,425,5,116,0,0,425,426,5,105,0,0,426,427,5,111,0,0,427,
428,5,110,0,0,428,429,5,115,0,0,429,54,1,0,0,0,430,431,5,99,0,0,431,432,
5,111,0,0,432,433,5,110,0,0,433,434,5,102,0,0,434,435,5,105,0,0,435,436,
5,100,0,0,436,437,5,101,0,0,437,438,5,110,0,0,438,439,5,116,0,0,439,56,1,
0,0,0,440,441,5,99,0,0,441,442,5,111,0,0,442,443,5,110,0,0,443,444,5,116,
0,0,444,445,5,101,0,0,445,446,5,120,0,0,446,447,5,116,0,0,447,58,1,0,0,0,
448,449,5,99,0,0,449,450,5,111,0,0,450,451,5,119,0,0,451,60,1,0,0,0,452,
453,5,100,0,0,453,454,5,101,0,0,454,455,5,102,0,0,455,456,5,97,0,0,456,457,
5,117,0,0,457,458,5,108,0,0,458,459,5,116,0,0,459,62,1,0,0,0,460,461,5,100,
0,0,461,462,5,101,0,0,462,463,5,102,0,0,463,464,5,105,0,0,464,465,5,110,
0,0,465,466,5,101,0,0,466,467,5,100,0,0,467,64,1,0,0,0,468,469,5,100,0,0,
469,470,5,105,0,0,470,471,5,103,0,0,471,472,5,114,0,0,472,473,5,101,0,0,
473,474,5,115,0,0,474,475,5,115,0,0,475,476,5,105,0,0,476,477,5,111,0,0,
477,478,5,110,0,0,478,66,1,0,0,0,479,480,5,100,0,0,480,481,5,105,0,0,481,
482,5,103,0,0,482,483,5,114,0,0,483,484,5,101,0,0,484,485,5,115,0,0,485,
486,5,115,0,0,486,487,5,105,0,0,487,488,5,111,0,0,488,489,5,110,0,0,489,
490,5,115,0,0,490,68,1,0,0,0,491,492,5,100,0,0,492,493,5,105,0,0,493,494,
5,115,0,0,494,495,5,97,0,0,495,496,5,98,0,0,496,497,5,108,0,0,497,498,5,
101,0,0,498,70,1,0,0,0,499,500,5,100,0,0,500,501,5,111,0,0,501,72,1,0,0,
0,502,503,5,100,0,0,503,504,5,111,0,0,504,505,5,110,0,0,505,506,5,101,0,
0,506,74,1,0,0,0,507,508,5,101,0,0,508,509,5,108,0,0,509,510,5,115,0,0,510,
511,5,101,0,0,511,76,1,0,0,0,512,513,5,101,0,0,513,514,5,110,0,0,514,515,
5,97,0,0,515,516,5,98,0,0,516,517,5,108,0,0,517,518,5,101,0,0,518,78,1,0,
0,0,519,520,5,101,0,0,520,521,5,120,0,0,521,522,5,116,0,0,522,523,5,101,
0,0,523,524,5,114,0,0,524,525,5,110,0,0,525,526,5,97,0,0,526,527,5,108,0,
0,527,80,1,0,0,0,528,529,5,101,0,0,529,530,5,120,0,0,530,531,5,105,0,0,531,
532,5,116,0,0,532,82,1,0,0,0,533,534,5,102,0,0,534,535,5,105,0,0,535,536,
5,110,0,0,536,537,5,97,0,0,537,538,5,108,0,0,538,539,5,108,0,0,539,540,5,
121,0,0,540,84,1,0,0,0,541,542,5,102,0,0,542,543,5,111,0,0,543,544,5,114,
0,0,544,86,1,0,0,0,545,546,5,102,0,0,546,547,5,117,0,0,547,548,5,110,0,0,
548,549,5,99,0,0,549,550,5,116,0,0,550,551,5,105,0,0,551,552,5,111,0,0,552,
553,5,110,0,0,553,88,1,0,0,0,554,555,5,103,0,0,555,556,5,108,0,0,556,557,
5,111,0,0,557,558,5,98,0,0,558,559,5,97,0,0,559,560,5,108,0,0,560,90,1,0,
0,0,561,562,5,103,0,0,562,563,5,111,0,0,563,564,5,116,0,0,564,565,5,111,
0,0,565,92,1,0,0,0,566,567,5,105,0,0,567,568,5,102,0,0,568,94,1,0,0,0,569,
570,5,105,0,0,570,571,5,109,0,0,571,572,5,112,0,0,572,573,5,111,0,0,573,
574,5,114,0,0,574,575,5,116,0,0,575,96,1,0,0,0,576,577,5,105,0,0,577,578,
5,110,0,0,578,98,1,0,0,0,579,580,5,105,0,0,580,581,5,110,0,0,581,582,5,112,
0,0,582,583,5,117,0,0,583,584,5,116,0,0,584,100,1,0,0,0,585,586,5,105,0,
0,586,587,5,115,0,0,587,102,1,0,0,0,588,589,5,108,0,0,589,590,5,105,0,0,
590,591,5,98,0,0,591,592,5,114,0,0,592,593,5,97,0,0,593,594,5,114,0,0,594,
595,5,121,0,0,595,104,1,0,0,0,596,597,5,108,0,0,597,598,5,111,0,0,598,599,
5,103,0,0,599,600,5,101,0,0,600,601,5,120,0,0,601,602,5,112,0,0,602,603,
5,114,0,0,603,106,1,0,0,0,604,605,5,110,0,0,605,606,5,111,0,0,606,607,5,
100,0,0,607,608,5,101,0,0,608,108,1,0,0,0,609,610,5,110,0,0,610,611,5,111,
0,0,611,612,5,116,0,0,612,110,1,0,0,0,613,614,5,111,0,0,614,615,5,102,0,
0,615,112,1,0,0,0,616,617,5,111,0,0,617,618,5,110,0,0,618,114,1,0,0,0,619,
620,5,111,0,0,620,621,5,110,0,0,621,622,5,100,0,0,622,623,5,105,0,0,623,
624,5,103,0,0,624,625,5,114,0,0,625,626,5,101,0,0,626,627,5,116,0,0,627,
628,5,117,0,0,628,629,5,114,0,0,629,630,5,110,0,0,630,116,1,0,0,0,631,632,
5,111,0,0,632,633,5,110,0,0,633,634,5,101,0,0,634,635,5,120,0,0,635,636,
5,105,0,0,636,637,5,116,0,0,637,118,1,0,0,0,638,639,5,111,0,0,639,640,5,
117,0,0,640,641,5,116,0,0,641,642,5,112,0,0,642,643,5,117,0,0,643,644,5,
116,0,0,644,120,1,0,0,0,645,646,5,112,0,0,646,647,5,114,0,0,647,648,5,101,
0,0,648,649,5,112,0,0,649,650,5,114,0,0,650,651,5,111,0,0,651,652,5,99,0,
0,652,653,5,101,0,0,653,654,5,115,0,0,654,655,5,115,0,0,655,656,5,111,0,
0,656,657,5,114,0,0,657,122,1,0,0,0,658,659,5,112,0,0,659,660,5,114,0,0,
660,661,5,105,0,0,661,662,5,111,0,0,662,663,5,114,0,0,663,664,5,105,0,0,
664,665,5,116,0,0,665,666,5,121,0,0,666,124,1,0,0,0,667,668,5,114,0,0,668,
669,5,101,0,0,669,670,5,116,0,0,670,671,5,117,0,0,671,672,5,114,0,0,672,
673,5,110,0,0,673,126,1,0,0,0,674,675,5,115,0,0,675,676,5,97,0,0,676,677,
5,118,0,0,677,678,5,101,0,0,678,128,1,0,0,0,679,680,5,115,0,0,680,681,5,
101,0,0,681,682,5,116,0,0,682,130,1,0,0,0,683,684,5,115,0,0,684,685,5,104,
0,0,685,686,5,97,0,0,686,687,5,114,0,0,687,688,5,101,0,0,688,689,5,100,0,
0,689,132,1,0,0,0,690,691,5,115,0,0,691,692,5,116,0,0,692,693,5,97,0,0,693,
694,5,114,0,0,694,695,5,116,0,0,695,134,1,0,0,0,696,697,5,115,0,0,697,698,
5,116,0,0,698,699,5,97,0,0,699,700,5,116,0,0,700,701,5,105,0,0,701,702,5,
99,0,0,702,136,1,0,0,0,703,704,5,115,0,0,704,705,5,121,0,0,705,706,5,110,
0,0,706,707,5,99,0,0,707,138,1,0,0,0,708,709,5,115,0,0,709,710,5,121,0,0,
710,711,5,115,0,0,711,712,5,116,0,0,712,713,5,101,0,0,713,714,5,109,0,0,
714,140,1,0,0,0,715,716,5,116,0,0,716,717,5,105,0,0,717,718,5,109,0,0,718,
719,5,101,0,0,719,720,5,111,0,0,720,721,5,117,0,0,721,722,5,116,0,0,722,
142,1,0,0,0,723,724,5,116,0,0,724,725,5,114,0,0,725,726,5,97,0,0,726,727,
5,110,0,0,727,728,5,115,0,0,728,729,5,105,0,0,729,730,5,116,0,0,730,731,
5,105,0,0,731,732,5,111,0,0,732,733,5,110,0,0,733,734,5,115,0,0,734,144,
1,0,0,0,735,736,5,116,0,0,736,737,5,97,0,0,737,738,5,103,0,0,738,739,5,115,
0,0,739,146,1,0,0,0,740,741,5,116,0,0,741,742,5,114,0,0,742,743,5,121,0,
0,743,148,1,0,0,0,744,745,5,116,0,0,745,746,5,121,0,0,746,747,5,112,0,0,
747,748,5,101,0,0,748,150,1,0,0,0,749,750,5,118,0,0,750,751,5,97,0,0,751,
752,5,114,0,0,752,152,1,0,0,0,753,754,5,119,0,0,754,755,5,97,0,0,755,756,
5,105,0,0,756,757,5,116,0,0,757,154,1,0,0,0,758,759,5,119,0,0,759,760,5,
104,0,0,760,761,5,101,0,0,761,762,5,110,0,0,762,156,1,0,0,0,763,764,5,102,
0,0,764,765,5,105,0,0,765,766,5,108,0,0,766,767,5,108,0,0,767,158,1,0,0,
0,768,769,5,35,0,0,769,160,1,0,0,0,770,771,5,36,0,0,771,162,1,0,0,0,772,
774,3,29,14,0,773,772,1,0,0,0,773,774,1,0,0,0,774,775,1,0,0,0,775,776,3,
167,83,0,776,164,1,0,0,0,777,779,3,29,14,0,778,777,1,0,0,0,778,779,1,0,0,
0,779,780,1,0,0,0,780,781,3,169,84,0,781,166,1,0,0,0,782,783,3,169,84,0,
783,787,3,215,107,0,784,786,7,3,0,0,785,784,1,0,0,0,786,789,1,0,0,0,787,
785,1,0,0,0,787,788,1,0,0,0,788,791,1,0,0,0,789,787,1,0,0,0,790,792,3,171,
85,0,791,790,1,0,0,0,791,792,1,0,0,0,792,806,1,0,0,0,793,795,3,215,107,0,
794,796,7,3,0,0,795,794,1,0,0,0,796,797,1,0,0,0,797,795,1,0,0,0,797,798,
1,0,0,0,798,800,1,0,0,0,799,801,3,171,85,0,800,799,1,0,0,0,800,801,1,0,0,
0,801,806,1,0,0,0,802,803,3,169,84,0,803,804,3,171,85,0,804,806,1,0,0,0,
805,782,1,0,0,0,805,793,1,0,0,0,805,802,1,0,0,0,806,168,1,0,0,0,807,816,
5,48,0,0,808,812,7,4,0,0,809,811,7,3,0,0,810,809,1,0,0,0,811,814,1,0,0,0,
812,810,1,0,0,0,812,813,1,0,0,0,813,816,1,0,0,0,814,812,1,0,0,0,815,807,
1,0,0,0,815,808,1,0,0,0,816,170,1,0,0,0,817,819,7,5,0,0,818,820,7,6,0,0,
819,818,1,0,0,0,819,820,1,0,0,0,820,822,1,0,0,0,821,823,7,3,0,0,822,821,
1,0,0,0,823,824,1,0,0,0,824,822,1,0,0,0,824,825,1,0,0,0,825,172,1,0,0,0,
826,827,5,116,0,0,827,828,5,114,0,0,828,829,5,117,0,0,829,836,5,101,0,0,
830,831,5,102,0,0,831,832,5,97,0,0,832,833,5,108,0,0,833,834,5,115,0,0,834,
836,5,101,0,0,835,826,1,0,0,0,835,830,1,0,0,0,836,174,1,0,0,0,837,838,5,
101,0,0,838,839,5,109,0,0,839,840,5,112,0,0,840,841,5,116,0,0,841,842,5,
121,0,0,842,176,1,0,0,0,843,844,5,110,0,0,844,845,5,117,0,0,845,846,5,109,
0,0,846,847,5,98,0,0,847,848,5,101,0,0,848,849,5,114,0,0,849,178,1,0,0,0,
850,851,5,98,0,0,851,852,5,111,0,0,852,853,5,111,0,0,853,854,5,108,0,0,854,
855,5,101,0,0,855,856,5,97,0,0,856,857,5,110,0,0,857,180,1,0,0,0,858,859,
5,115,0,0,859,860,5,116,0,0,860,861,5,114,0,0,861,862,5,105,0,0,862,863,
5,110,0,0,863,864,5,103,0,0,864,182,1,0,0,0,865,866,5,117,0,0,866,867,5,
110,0,0,867,868,5,107,0,0,868,869,5,110,0,0,869,870,5,111,0,0,870,871,5,
119,0,0,871,872,5,110,0,0,872,184,1,0,0,0,873,874,5,110,0,0,874,875,5,117,
0,0,875,876,5,108,0,0,876,877,5,108,0,0,877,186,1,0,0,0,878,879,5,93,0,0,
879,188,1,0,0,0,880,881,5,91,0,0,881,190,1,0,0,0,882,883,5,62,0,0,883,192,
1,0,0,0,884,885,5,60,0,0,885,194,1,0,0,0,886,887,5,41,0,0,887,196,1,0,0,
0,888,889,5,40,0,0,889,198,1,0,0,0,890,891,5,125,0,0,891,200,1,0,0,0,892,
893,5,123,0,0,893,202,1,0,0,0,894,895,5,34,0,0,895,204,1,0,0,0,896,897,5,
96,0,0,897,206,1,0,0,0,898,899,5,58,0,0,899,208,1,0,0,0,900,901,5,59,0,0,
901,210,1,0,0,0,902,903,5,44,0,0,903,212,1,0,0,0,904,905,5,63,0,0,905,214,
1,0,0,0,906,907,5,46,0,0,907,216,1,0,0,0,908,909,5,61,0,0,909,218,1,0,0,
0,910,911,5,43,0,0,911,912,5,61,0,0,912,220,1,0,0,0,913,914,5,45,0,0,914,
915,5,61,0,0,915,222,1,0,0,0,916,917,5,61,0,0,917,918,5,61,0,0,918,224,1,
0,0,0,919,920,5,33,0,0,920,921,5,61,0,0,921,226,1,0,0,0,922,923,5,62,0,0,
923,924,5,61,0,0,924,228,1,0,0,0,925,926,5,60,0,0,926,927,5,61,0,0,927,230,
1,0,0,0,928,929,5,124,0,0,929,933,5,124,0,0,930,931,5,111,0,0,931,933,5,
114,0,0,932,928,1,0,0,0,932,930,1,0,0,0,933,232,1,0,0,0,934,935,5,124,0,
0,935,234,1,0,0,0,936,937,5,38,0,0,937,942,5,38,0,0,938,939,5,97,0,0,939,
940,5,110,0,0,940,942,5,100,0,0,941,936,1,0,0,0,941,938,1,0,0,0,942,236,
1,0,0,0,943,946,5,33,0,0,944,946,3,109,54,0,945,943,1,0,0,0,945,944,1,0,
0,0,946,238,1,0,0,0,947,949,5,64,0,0,948,947,1,0,0,0,948,949,1,0,0,0,949,
950,1,0,0,0,950,954,3,243,121,0,951,953,3,245,122,0,952,951,1,0,0,0,953,
956,1,0,0,0,954,952,1,0,0,0,954,955,1,0,0,0,955,969,1,0,0,0,956,954,1,0,
0,0,957,959,5,64,0,0,958,957,1,0,0,0,958,959,1,0,0,0,959,960,1,0,0,0,960,
961,3,241,120,0,961,965,3,243,121,0,962,964,3,245,122,0,963,962,1,0,0,0,
964,967,1,0,0,0,965,963,1,0,0,0,965,966,1,0,0,0,966,969,1,0,0,0,967,965,
1,0,0,0,968,948,1,0,0,0,968,958,1,0,0,0,969,240,1,0,0,0,970,971,5,95,0,0,
971,242,1,0,0,0,972,973,7,7,0,0,973,244,1,0,0,0,974,977,3,243,121,0,975,
977,3,241,120,0,976,974,1,0,0,0,976,975,1,0,0,0,977,246,1,0,0,0,978,979,
5,47,0,0,979,980,5,47,0,0,980,984,1,0,0,0,981,983,8,8,0,0,982,981,1,0,0,
0,983,986,1,0,0,0,984,982,1,0,0,0,984,985,1,0,0,0,985,987,1,0,0,0,986,984,
1,0,0,0,987,988,6,123,0,0,988,248,1,0,0,0,989,990,5,47,0,0,990,991,5,42,
0,0,991,992,5,42,0,0,992,996,1,0,0,0,993,995,9,0,0,0,994,993,1,0,0,0,995,
998,1,0,0,0,996,997,1,0,0,0,996,994,1,0,0,0,997,999,1,0,0,0,998,996,1,0,
0,0,999,1000,5,42,0,0,1000,1001,5,47,0,0,1001,1002,1,0,0,0,1002,1003,6,124,
1,0,1003,250,1,0,0,0,1004,1005,5,47,0,0,1005,1006,5,42,0,0,1006,1010,1,0,
0,0,1007,1009,9,0,0,0,1008,1007,1,0,0,0,1009,1012,1,0,0,0,1010,1011,1,0,
0,0,1010,1008,1,0,0,0,1011,1013,1,0,0,0,1012,1010,1,0,0,0,1013,1014,5,42,
0,0,1014,1015,5,47,0,0,1015,1016,1,0,0,0,1016,1017,6,125,0,0,1017,252,1,
0,0,0,1018,1020,7,9,0,0,1019,1018,1,0,0,0,1020,1021,1,0,0,0,1021,1019,1,
0,0,0,1021,1022,1,0,0,0,1022,1023,1,0,0,0,1023,1024,6,126,2,0,1024,254,1,
0,0,0,31,0,329,338,343,347,351,773,778,787,791,797,800,805,812,815,819,824,
835,932,941,945,948,954,958,965,968,976,984,996,1010,1021,3,0,1,0,0,2,0,
6,0,0];


const atn = new antlr4.atn.ATNDeserializer().deserialize(serializedATN);

const decisionsToDFA = atn.decisionToState.map( (ds, index) => new antlr4.dfa.DFA(ds, index) );

export default class graphLexer extends antlr4.Lexer {

    static grammarFileName = "graph.g4";
    static channelNames = [ "DEFAULT_TOKEN_CHANNEL", "HIDDEN" ];
	static modeNames = [ "DEFAULT_MODE" ];
	static literalNames = [ null, "'from'", "'starting'", "'exiting'", "'opened'", 
                         "'blockMessage'", "'amdVoice'", "'languageDetected'", 
                         null, "'+'", "'-'", "'*'", "'/'", "'%'", "'apply'", 
                         "'with'", "'as'", "'async'", "'block'", "'blockcall'", 
                         "'catch'", "'comment'", "'conditions'", "'confident'", 
                         "'context'", "'cow'", "'default'", "'defined'", 
                         "'digression'", "'digressions'", "'disable'", "'do'", 
                         "'done'", "'else'", "'enable'", "'external'", "'exit'", 
                         "'finally'", "'for'", "'function'", "'global'", 
                         "'goto'", "'if'", "'import'", "'in'", "'input'", 
                         "'is'", "'library'", "'logexpr'", "'node'", "'not'", 
                         "'of'", "'on'", "'ondigreturn'", "'onexit'", "'output'", 
                         "'preprocessor'", "'priority'", "'return'", "'save'", 
                         "'set'", "'shared'", "'start'", "'static'", "'sync'", 
                         "'system'", "'timeout'", "'transitions'", "'tags'", 
                         "'try'", "'type'", "'var'", "'wait'", "'when'", 
                         "'fill'", "'#'", "'$'", null, null, null, "'empty'", 
                         "'number'", "'boolean'", "'string'", "'unknown'", 
                         "'null'", "']'", "'['", "'>'", "'<'", "')'", "'('", 
                         "'}'", "'{'", "'\"'", "'`'", "':'", "';'", "','", 
                         "'?'", "'.'", "'='", "'+='", "'-='", "'=='", "'!='", 
                         "'>='", "'<='", null, "'|'" ];
	static symbolicNames = [ null, null, null, null, null, null, null, null, 
                          "StringLiteral", "PLUS", "MINUS", "STAR", "SLASH", 
                          "PERCENT", "APPLY", "WITH", "AS", "ASYNC", "BLOCK", 
                          "BLOCKCALL", "CATCH", "COMMENT", "CONDITIONS", 
                          "CONFIDENT", "CONTEXT", "COW", "DEFAULT", "DEFINED", 
                          "DIGRESSION", "DIGRESSIONS", "DISABLE", "DO", 
                          "DONE", "ELSE", "ENABLE", "EXTERNAL", "EXIT", 
                          "FINALLY", "FOR", "FUNCTION", "GLOBAL", "GOTO", 
                          "IF", "IMPORT", "IN", "INPUT", "IS", "LIBRARY", 
                          "LOGEXPR", "NODE", "NOT", "OF", "ON", "ONDIGRETURN", 
                          "ONEXIT", "OUTPUT", "PREPROCESSOR", "PRIORITY", 
                          "RETURN", "SAVE", "SET", "SHARED", "START", "STATIC", 
                          "SYNC", "SYSTEM", "TIMEOUT", "TRANSITIONS", "TAGS", 
                          "TRY", "TYPE", "VAR", "WAIT", "WHEN", "FILL", 
                          "BuiltinMark", "ContextMark", "DecimalLiteral", 
                          "DecimalIntegerLiteral", "BooleanLiteral", "Empty", 
                          "Number", "Boolean", "String", "Unknown", "NULL", 
                          "RBRACKET", "LBRACKET", "RANGLE", "LANGLE", "RPAREN", 
                          "LPAREN", "RCURL", "LCURL", "QUOTE", "BACKTRICK", 
                          "COLON", "SEMICOLON", "COMMA", "QMARK", "PERIOD", 
                          "EQUALSIGN", "AddOp", "RemoveOp", "EqualOp", "NotEqualOp", 
                          "GreaterEqualOp", "LessEqualOp", "OrOp", "TypeOr", 
                          "AndOp", "NotOperator", "ID", "LineComment", "DocsComment", 
                          "BlockComment", "Whitespace" ];
	static ruleNames = [ "T__0", "T__1", "T__2", "T__3", "T__4", "T__5", "T__6", 
                      "SafeCodePoint", "StringLiteral", "StringCharacter", 
                      "MultilineStringCharacter", "MultilineEscapeSequence", 
                      "EscapeSequence", "PLUS", "MINUS", "STAR", "SLASH", 
                      "PERCENT", "APPLY", "WITH", "AS", "ASYNC", "BLOCK", 
                      "BLOCKCALL", "CATCH", "COMMENT", "CONDITIONS", "CONFIDENT", 
                      "CONTEXT", "COW", "DEFAULT", "DEFINED", "DIGRESSION", 
                      "DIGRESSIONS", "DISABLE", "DO", "DONE", "ELSE", "ENABLE", 
                      "EXTERNAL", "EXIT", "FINALLY", "FOR", "FUNCTION", 
                      "GLOBAL", "GOTO", "IF", "IMPORT", "IN", "INPUT", "IS", 
                      "LIBRARY", "LOGEXPR", "NODE", "NOT", "OF", "ON", "ONDIGRETURN", 
                      "ONEXIT", "OUTPUT", "PREPROCESSOR", "PRIORITY", "RETURN", 
                      "SAVE", "SET", "SHARED", "START", "STATIC", "SYNC", 
                      "SYSTEM", "TIMEOUT", "TRANSITIONS", "TAGS", "TRY", 
                      "TYPE", "VAR", "WAIT", "WHEN", "FILL", "BuiltinMark", 
                      "ContextMark", "DecimalLiteral", "DecimalIntegerLiteral", 
                      "DecimalLiteralAbsoluteValue", "DecimalIntegerLiteralAbsoluteValue", 
                      "ExponentPart", "BooleanLiteral", "Empty", "Number", 
                      "Boolean", "String", "Unknown", "NULL", "RBRACKET", 
                      "LBRACKET", "RANGLE", "LANGLE", "RPAREN", "LPAREN", 
                      "RCURL", "LCURL", "QUOTE", "BACKTRICK", "COLON", "SEMICOLON", 
                      "COMMA", "QMARK", "PERIOD", "EQUALSIGN", "AddOp", 
                      "RemoveOp", "EqualOp", "NotEqualOp", "GreaterEqualOp", 
                      "LessEqualOp", "OrOp", "TypeOr", "AndOp", "NotOperator", 
                      "ID", "Underscore", "NonUnderscoreIdSymbol", "AnyIdSymbol", 
                      "LineComment", "DocsComment", "BlockComment", "Whitespace" ];

    constructor(input) {
        super(input)
        this._interp = new antlr4.atn.LexerATNSimulator(this, atn, decisionsToDFA, new antlr4.atn.PredictionContextCache());
    }
}

graphLexer.EOF = antlr4.Token.EOF;
graphLexer.T__0 = 1;
graphLexer.T__1 = 2;
graphLexer.T__2 = 3;
graphLexer.T__3 = 4;
graphLexer.T__4 = 5;
graphLexer.T__5 = 6;
graphLexer.T__6 = 7;
graphLexer.StringLiteral = 8;
graphLexer.PLUS = 9;
graphLexer.MINUS = 10;
graphLexer.STAR = 11;
graphLexer.SLASH = 12;
graphLexer.PERCENT = 13;
graphLexer.APPLY = 14;
graphLexer.WITH = 15;
graphLexer.AS = 16;
graphLexer.ASYNC = 17;
graphLexer.BLOCK = 18;
graphLexer.BLOCKCALL = 19;
graphLexer.CATCH = 20;
graphLexer.COMMENT = 21;
graphLexer.CONDITIONS = 22;
graphLexer.CONFIDENT = 23;
graphLexer.CONTEXT = 24;
graphLexer.COW = 25;
graphLexer.DEFAULT = 26;
graphLexer.DEFINED = 27;
graphLexer.DIGRESSION = 28;
graphLexer.DIGRESSIONS = 29;
graphLexer.DISABLE = 30;
graphLexer.DO = 31;
graphLexer.DONE = 32;
graphLexer.ELSE = 33;
graphLexer.ENABLE = 34;
graphLexer.EXTERNAL = 35;
graphLexer.EXIT = 36;
graphLexer.FINALLY = 37;
graphLexer.FOR = 38;
graphLexer.FUNCTION = 39;
graphLexer.GLOBAL = 40;
graphLexer.GOTO = 41;
graphLexer.IF = 42;
graphLexer.IMPORT = 43;
graphLexer.IN = 44;
graphLexer.INPUT = 45;
graphLexer.IS = 46;
graphLexer.LIBRARY = 47;
graphLexer.LOGEXPR = 48;
graphLexer.NODE = 49;
graphLexer.NOT = 50;
graphLexer.OF = 51;
graphLexer.ON = 52;
graphLexer.ONDIGRETURN = 53;
graphLexer.ONEXIT = 54;
graphLexer.OUTPUT = 55;
graphLexer.PREPROCESSOR = 56;
graphLexer.PRIORITY = 57;
graphLexer.RETURN = 58;
graphLexer.SAVE = 59;
graphLexer.SET = 60;
graphLexer.SHARED = 61;
graphLexer.START = 62;
graphLexer.STATIC = 63;
graphLexer.SYNC = 64;
graphLexer.SYSTEM = 65;
graphLexer.TIMEOUT = 66;
graphLexer.TRANSITIONS = 67;
graphLexer.TAGS = 68;
graphLexer.TRY = 69;
graphLexer.TYPE = 70;
graphLexer.VAR = 71;
graphLexer.WAIT = 72;
graphLexer.WHEN = 73;
graphLexer.FILL = 74;
graphLexer.BuiltinMark = 75;
graphLexer.ContextMark = 76;
graphLexer.DecimalLiteral = 77;
graphLexer.DecimalIntegerLiteral = 78;
graphLexer.BooleanLiteral = 79;
graphLexer.Empty = 80;
graphLexer.Number = 81;
graphLexer.Boolean = 82;
graphLexer.String = 83;
graphLexer.Unknown = 84;
graphLexer.NULL = 85;
graphLexer.RBRACKET = 86;
graphLexer.LBRACKET = 87;
graphLexer.RANGLE = 88;
graphLexer.LANGLE = 89;
graphLexer.RPAREN = 90;
graphLexer.LPAREN = 91;
graphLexer.RCURL = 92;
graphLexer.LCURL = 93;
graphLexer.QUOTE = 94;
graphLexer.BACKTRICK = 95;
graphLexer.COLON = 96;
graphLexer.SEMICOLON = 97;
graphLexer.COMMA = 98;
graphLexer.QMARK = 99;
graphLexer.PERIOD = 100;
graphLexer.EQUALSIGN = 101;
graphLexer.AddOp = 102;
graphLexer.RemoveOp = 103;
graphLexer.EqualOp = 104;
graphLexer.NotEqualOp = 105;
graphLexer.GreaterEqualOp = 106;
graphLexer.LessEqualOp = 107;
graphLexer.OrOp = 108;
graphLexer.TypeOr = 109;
graphLexer.AndOp = 110;
graphLexer.NotOperator = 111;
graphLexer.ID = 112;
graphLexer.LineComment = 113;
graphLexer.DocsComment = 114;
graphLexer.BlockComment = 115;
graphLexer.Whitespace = 116;



